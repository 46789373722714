import type { SelectOption } from '@core/app/components/base/ui/select/BaseUiSelect.vue'
import type { ApiResponse } from '@composable-api/api.response'

export type Pagination = {
    page: number
    perPage: number
    perPageOptions: number[]
}

export function usePagination(options?: {
    perPage?: number
}) {
    const pagination = ref({
        page: 1,
        perPage: options?.perPage || 5,
        perPageOptions: [5, 10, 20, 50],
    })

    return {
        pagination,
    }
}

export function usePaginationInfo<T extends number>(
    pagination: InstanceType<typeof ProductFiltersPaginationModel> | ReturnType<ApiResponse<any>['getMeta']> | null | undefined
) {
    const range = computed(() => {
        function calculateRange(page: number, perPage: number, itemCount: number) {
            if (itemCount === 0) {
                return { start: 0, end: 0 }
            }
            const start = (page - 1) * perPage + 1
            const end = Math.min(page * perPage, itemCount)
            return { start, end }
        }

        if (pagination instanceof ProductFiltersPaginationModel) {
            return calculateRange(pagination.page ?? 0, pagination.perPage ?? 0, pagination.itemsCount ?? 0)
        } else if (pagination && pagination.records !== undefined) {
            return calculateRange(pagination.page, pagination.per_page, pagination.records)
        }

        return { start: 0, end: 0 }
    })

    const total = computed(() => {
        if (pagination instanceof ProductFiltersPaginationModel) {
            return pagination.itemsCount
        } else {
            return pagination?.records
        }
    })

    return {
        range,
        total,
    }
}

export function usePerPageSelected<T extends number>(
    model: Ref<SelectOption<T>['value'] | SelectOption<number>['value'] | { perPage: number } | null | undefined>
) {
    const selected = computed({
        get() {
            if (typeof model.value === 'object' && model.value && 'perPage' in model.value) {
                return model.value.perPage as number
            }
            return model.value
        },
        set(val) {
            if (typeof model.value === 'object' && model.value && 'perPage' in model.value) {
                if (typeof val !== 'number') {
                    return
                }
                model.value.perPage = val
            } else {
                model.value = val
            }
        },
    })

    return {
        selected,
    }
}
